import { Product, ColourVariation } from "@/types"

export const getProductBase = (product: Product | ColourVariation) => {
  if (product.product_type === "fabric") {
    return { path: "fabrics", name: "Fabrics" }
  }

  if (product.product_type === "tooling") {
    return { path: "tooling", name: "Tooling" }
  }

  if (product.product_type === "point-of-sale") {
    return { path: "point-of-sale", name: "Point of Sale" }
  }

  return { path: "components", name: "Components" }
}

export const getProductUrl = (product: Product | ColourVariation) => {
  const { path } = getProductBase(product)

  return `/${path}/${product.slug}/`
}

export const getProductDefaultQuantityUnit = (product: Product) => {
  if (product.product_type === "components" && !product.cut_length_warehouse) {
    return "Box"
  }

  if (product.product_type === "components" && product.cut_length_warehouse) {
    return product.stock_unit.replaceAll(/\d/g, "")
  }

  return undefined
}

export const getProductDefaultQuantity = (
  product: Product,
  { defaults }: { defaults?: boolean } = {},
): number => {
  let quantity = 0

  if (product.product_type === "components") {
    if (defaults) {
      if (product.cut_length_warehouse) {
        return Number(product.cut_length_unit.replaceAll(/[^\d.]/g, "")) || 1
      }
      return 1
    }

    return 0
  }

  if (["fabric", "components"].includes(product.product_type)) {
    quantity = product.roll_length
  }

  return quantity > 0 ? quantity : 1
}
